<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>아이디어 접수</h3>
      </div>
      <div style="background-color: #ececec; text-align: center;" class="my-3">
        <mdb-row>
          <mdb-col></mdb-col>
          <mdb-col>
            <mdb-select v-model="basicOptions" placeholder="-검색선택-" @getValue="getSelectValue"/>
          </mdb-col>
          <mdb-col>
            <mdb-input v-model="search" placeholder="검색어를 입력하세요."/>
          </mdb-col>
          <mdb-col></mdb-col>
        </mdb-row>
      </div>
      <p class="mt-4 mb-1">총 게시물 <strong style="color: red">{{ totCount }}</strong> 건</p>
      <mdb-datatable-2
          class="mb-3"
          v-model="data"
          hover
          :searching="{value: search, field: fieldName} "
          @selected="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </mdb-col>
    <mdb-col class="mb-5 text-right" style="text-align: center">
      <mdb-btn outline="success" small icon="pen" @click="goWriteForm" v-if="isPlay">접수</mdb-btn>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbCol, mdbRow, mdbSelect, mdbContainer, mdbDatatable2, mdbInput, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";
import idea_columns from "../../../assets/idea_columns";

export default {
  name: 'IdeaList',
  components: {
    mdbContainer,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      basicOptions: [
        {text: "제목", value: "title"},
        {text: "작성자", value: "writer"},
      ],
      fbCollection: 'boardState',
      fbDoc: 'ideaBoard',
      year: 2024,
      rowData: [],
      selected: null,
      search: '',
      data: {
        columns: idea_columns,
        rows: []
      },
      fieldName: 'title',
      totCount: 0,
      fields: '',
      isPlay: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.onFirebaseGetAllArrayData();
      this.getIdeaBoardState();
    },
    onSelected(value) {
      this.selected = value;
      const self = this;
      self.$router.push({name: 'ideaView', query: {id: value.id}}).catch((err) => {
        console.log(err)
      });
    },
    getSelectValue(value) {
      // console.log(value);
      this.fieldName = value
    },
    async getIdeaBoardState() {
      const self = this;
      const db = firebase.firestore();
      const result = await db.collection(self.fbCollection).doc(self.fbDoc).get()
      self.isPlay = result.data().isPlay;
    },
    onFirebaseGetAllArrayData() {
      const db = firebase.firestore();
      const self = this;
      if (self.year < 2020 || self.year > 2024) {
        return alert('조회할 자료가 없습니다.');
      }
      self.data.rows.splice(0)
      const startDate = self.year + '-01-01'
      let start = new Date(startDate);
      start.setHours(0, 0, 0, 0)
      const endDate = self.year + '-12-31'
      let end = new Date(endDate);
      end.setHours(23, 59, 59, 999)

      let counter = 0;
      db.collection("ideaBoard")
          .where('reg_date', '>=', start)
          .where('reg_date', '<=', end)
          .orderBy("reg_date", "desc").get()
          .then((querySnapshot) => {
            self.totCount = querySnapshot.size;
            counter = self.totCount;
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data.order_number = counter;
              counter--;
              _data["id"] = doc.id;
              const date = new Date(_data.reg_date.seconds * 1000);
              _data.reg_date = self.getParseDate(date);
              self.data.rows.push(_data);
              // console.log(self.data.rows);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    },
    goWriteForm() {
      const self = this;
      if (self.$store.state.login) {
        self.$router.push('/ideaForm');
      } else {
        alert('로그인이 필요합니다.')
        self.$router.push('/user/login');
      }
    }
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}


</style>